import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../Images/QIcon.svg';
import LogoText from '../Images/qbatzLetter.svg';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Styles } from "../Styles/QbatzStyles";
import "./NavHeader.css"

function ResponsiveNavbar() {
    const navigate = useNavigate();
    const [activeSection, setActiveSection] = useState('home');
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        const handleNavigation = () => {
            setActiveSection('home');
        };

        handleNavigation();

        return () => {
            setActiveSection('home');
        };
    }, []);

    const handleScroll = (targetId) => {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setActiveSection(targetId);
        }
    };

    const handleContactUsClick = () => {
        navigate('/contact');
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 100);
    };


    const handleToggle = () => setExpanded(!expanded);

    return (
        <Navbar expand="lg" bg="white" fixed="top" expanded={expanded}>
            <Container>
                <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
                    <img
                        src={Logo}
                        alt="Logo"
                        width="40"
                        height="40"
                        className="d-inline-block align-top me-2"
                    />
                    <img src={LogoText} alt="Company Name" height="20" />
                </Navbar.Brand>

                <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    onClick={handleToggle}
                    style={{ border: 'none' }}
                >
                    {expanded ? <FaTimes style={{ color: 'black' }} /> : <FaBars style={{ color: 'black' }} />}
                </Navbar.Toggle>

                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto">
                        <Nav.Link
                            as={Link}
                            to="/"
                            className={`mx-3 ${activeSection === 'home' ? 'active' : ''}`}
                            onClick={() => handleScroll('home')}
                            style={{
                                fontWeight: Styles.FontSemibold,
                                fontSize: Styles.Font16,
                                fontFamily: Styles.fontFamilyRoboto,
                                color: activeSection === 'home' ? '#0047FF' : '#666666',
                                textDecoration: 'none',
                                padding: '10px 20px',
                            }}
                        >
                            Home
                        </Nav.Link>
                        <Nav.Link
                            as={Link}
                            to="/"
                            onClick={() => handleScroll('services')}
                            className={`mx-3 ${activeSection === 'services' ? 'active' : ''}`}
                            style={{
                                color: activeSection === 'services' ? '#0047FF' : '#666666',
                                fontWeight: Styles.FontSemibold,
                                fontSize: Styles.Font16,
                                fontFamily: Styles.fontFamilyRoboto,
                                textDecoration: 'none',
                                padding: '10px 20px',
                            }}
                        >
                            Services
                        </Nav.Link>
                        <Nav.Link
                            as={Link}
                            to="/"
                            onClick={() => handleScroll('products')}
                            className={`mx-3 ${activeSection === 'products' ? 'active' : ''}`}
                            style={{
                                color: activeSection === 'products' ? '#0047FF' : '#666666',
                                fontWeight: Styles.FontSemibold,
                                fontSize: Styles.Font16,
                                fontFamily: Styles.fontFamilyRoboto,
                                textDecoration: 'none',
                                padding: '10px 20px',
                            }}
                        >
                            Products
                        </Nav.Link>
                        <Nav.Link
                            as={Link}
                            to="/"
                            onClick={() => handleScroll('testimonials')}
                            className={`mx-3 ${activeSection === 'testimonials' ? 'active' : ''}`}
                            style={{
                                color: activeSection === 'testimonials' ? '#0047FF' : '#666666',
                                fontWeight: Styles.FontSemibold,
                                fontSize: Styles.Font16,
                                fontFamily: Styles.fontFamilyRoboto,
                                textDecoration: 'none',
                                padding: '10px 20px',
                            }}
                        >
                            Testimonials
                        </Nav.Link>
                    </Nav>

                    <Button
                        onClick={handleContactUsClick}
                        className="contact-us-button"
                        variant="primary"
                        style={{
                            backgroundColor: '#0047FF',
                            border: 'none',
                            borderRadius: '8px',
                            padding: '10px 20px',
                        }}
                    >
                        Contact Us
                    </Button>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default ResponsiveNavbar;

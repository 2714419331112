import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { CiMail } from "react-icons/ci";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaLinkedin, FaYoutube, FaFacebookSquare } from "react-icons/fa";
import { FaRegCopyright } from "react-icons/fa";
import pic from '../Images/logo.svg';
import { Styles } from '../Styles/QbatzStyles';

const Footer = () => {
  return (
    <div >
    <footer className="text-light " style={{ backgroundColor: '#141414', paddingTop: "30px" }}>

      <Container >
        <Row>
          <Col md={4}>

            <img
              src={pic}
              sizes='50px'
              className="img-fluid" style={{ marginTop: '25px', marginBottom: '12px' }} />
            <p style={{
              fontSize: Styles.Font14,
              color: "#FFFFFF",
              fontFamily: Styles.fontFamilyRoboto,
              fontWeight: Styles.FontNormal,
              marginBottom: '12px'
            }}>
              Empowering Digital Solutions, Simplifying Your<br /> Business

            </p>

          </Col>

          <Col md={2}>
            <h5 style={{
              fontSize: Styles.Font18,
              color: "#FFFFFF",
              fontFamily: Styles.fontFamilyMontserrat,
              fontWeight: Styles.FontSemibold,
              marginBottom: '12px',
              marginTop: '25px'
            }}>Product</h5>
            <ul className="list-unstyled"  >
              <li style={{ marginBottom: '12px' }}><a href="#home" className="text-light" style={{ textDecoration: 'none', fontSize: Styles.Font14, color: "#FFFFFF", fontFamily: "Roboto", fontWeight: 400 }}>About Us</a></li>
              <li style={{ marginBottom: '12px' }}><a href="#services" className="text-light" style={{ textDecoration: 'none', fontSize: Styles.Font14, color: "#FFFFFF", fontFamily: "Roboto", fontWeight: 400 }}>Services</a></li>
              <li style={{ marginBottom: '12px' }}><a href="#about" className="text-light" style={{ textDecoration: 'none',fontSize: Styles.Font14, color: "#FFFFFF", fontFamily: "Roboto", fontWeight: 400 }}>Contact Us</a></li>
            </ul>
          </Col>

          <Col md={3}>
            <h5 style={{
              fontSize: Styles.Font18,
              color: "#FFFFFF",
              fontFamily: Styles.fontFamilyMontserrat,
              fontWeight: Styles.FontSemibold,

              marginBottom: '12px', marginTop: '25px'
            }}>Head Office Address</h5>
            <ul className="list-unstyled">
              <li style={{ marginBottom: '12px' }}><a href="#careers" className="text-light" style={{ textDecoration: 'none', fontSize: Styles.Font14, color: "#FFFFFF", fontFamily: "Roboto", fontWeight: Styles.FontNormal }}>
                7/96, North Street, <br />Athisayapuram, VK Pudur (PO)<br />Tenkasi – 627861</a></li>

            </ul>
          </Col>

          <Col md={3}>
            <h5 style={{
              fontSize: Styles.Font18,
              color: "#FFFFFF",
              fontFamily: Styles.fontFamilyMontserrat,
              fontWeight: Styles.FontSemibold,
              marginBottom: '12px',
              marginTop: '25px'
            }}>Chennai Address</h5>
            <div>
              <p style={{fontSize:Styles.Font14, fontFamily:Styles.fontFamilyMontserrat, fontWeight:Styles.FontNormal}}>1B, Block 1, Neelkamal Apartment, Kazhipattur, Chennai - 603103</p>
            </div>
          </Col>
        </Row>

        <hr style={{ color: '#414141', marginTop: '30px' }}></hr>


        <p style={{ fontSize: 14, color: "#A6A6A6", fontFamily: Styles.fontFamilyMontserrat, fontWeight: Styles.FontMedium,textAlign:"center" }}><FaRegCopyright /> 2024 QBATZ. All rights reserved.</p>

      </Container>
    </footer>
    </div>
  );
};

export default Footer;

import React,{useEffect} from 'react';
import './ContactUs.css'; 
import 'bootstrap/dist/css/bootstrap.min.css'; 
import Footer from './Footer';
import NeedFooter from './Need_Footer';
import { Styles } from '../Styles/QbatzStyles';

function ContactUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div style={{paddingTop:"60px"}}>
            <div className="container">
                <div className="row g-4 justify-content-center" style={{ paddingTop: "50px", gap: "24px" }}>

                    <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12"
                        style={{ border: "1px solid #E2E2E2", borderRadius: "20px", padding: "30px" }}>
                        <h3 style={{
                            fontWeight: Styles.FontBold,
                            fontFamily: Styles.fontFamilyMontserrat,
                            fontSize: Styles.Font26
                        }}>
                            Our Contacts & Location
                        </h3>
                        <h5 style={{
                            fontSize: Styles.Font15,
                            fontWeight: Styles.FontMedium,
                            color: "#141414",
                            paddingTop: "5px",
                            fontFamily:Styles.fontFamilyRoboto
                        }}>You Can Find Us At The Following Location</h5>
                        <div style={{ paddingTop: "30px" }}>
                            <p style={{
                                fontWeight: Styles.FontBold,
                                fontFamily: Styles.fontFamilyMontserrat,
                                fontSize: Styles.Font18,
                                color: "#141414"
                            }}>Head Office Address:</p>
                            <p style={{
                                fontWeight: Styles.FontMedium,
                                fontFamily: Styles.fontFamilyMontserrat,
                                fontSize: Styles.Font14,
                                color: "#888"
                            }}>7/96, North Street, Athisayapuram, VK Pudur (PO) Tenkasi – 627861</p>
                        </div>
                        <div style={{ paddingTop: "30px" }}>
                            <p style={{
                                fontWeight: Styles.FontBold,
                                fontFamily: Styles.fontFamilyMontserrat,
                                fontSize: Styles.Font18,
                                color: "#141414"
                            }}>Chennai Address:</p>
                            <p style={{
                                fontWeight: Styles.FontMedium,
                                fontFamily: Styles.fontFamilyMontserrat,
                                fontSize: Styles.Font14,
                                color: "#888"
                            }}> 1B, Block 1, Neelkamal Apartment, Kazhipattur, Chennai - 603103</p>
                        </div>
                        <div style={{ paddingTop: "30px" }}>
                            <p style={{
                                fontWeight: Styles.FontBold,
                                fontFamily: Styles.fontFamilyMontserrat,
                                fontSize: Styles.Font18,
                                color: "#141414"
                            }}>Contact Info</p>
                            <ul className="contact-info" style={{
                                fontWeight: Styles.FontMedium,
                                fontFamily: Styles.fontFamilyMontserrat,
                                fontSize: Styles.Font14,
                                color: "#888"
                            }}>
                                <li>support@s3remotica.com</li>
                                <li>+91 8344715078</li>
                            </ul>

                        </div>
                    </div>


                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12" style={{ border: "1px solid #E2E2E2", borderRadius: "20px", padding: "30px" }}>
                        <h3 style={{ fontWeight: Styles.FontBold, fontFamily: Styles.fontFamilyMontserrat, fontSize:Styles.Font25 }}>
                            Get in Touch
                        </h3>
                        <p style={{
                            fontSize: Styles.Font15,
                            fontWeight: Styles.FontMedium,
                            fontFamily: Styles.fontFamilyRoboto,
                            color: "#141414",
                            paddingTop: "5px"
                        }}>Give us your contact details, and we will reach out to you as soon as possible!</p>
                        <form style={{ paddingTop: "10px" }}>
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <input type="text" className="form-control custom-input-height" id="name" placeholder="Your name" />
                                </div>

                                <div className="col-md-6">

                                    <input type="text" className="form-control custom-input-height" id="subject" placeholder="Your subject" />
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-md-6">

                                    <input type="email" className="form-control custom-input-height" id="email" placeholder=" Your email" />
                                </div>
                                <div className="col-md-6">

                                    <input type="text" className="form-control custom-input-height" id="phone" placeholder=" Your phone " />
                                </div>
                            </div>

                            <div className="mb-3">

                                <textarea className="form-control" id="message" rows="4" placeholder=" Your message"></textarea>
                            </div>

                            <button type="submit" className="btn btn-primary" style={{ marginTop: "20px" }}>Submit Message</button>
                        </form>

                    </div>
                </div>
            </div>
            <div style={{}}>
                <NeedFooter />

                <Footer />
            </div>
        </div>
    );
}

export default ContactUs;
















import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import briefcase from '../Images/briefcase.svg';
import codecircle from '../Images/code-circle.svg';
import profile from '../Images/profile.svg';
import messages from '../Images/messages.svg';
import chart from '../Images/chart.svg';
import { Styles } from '../Styles/QbatzStyles';
import './BestChoice.css';


const cardData = [
    { icon: briefcase, title: "Industry Expertise in Businesses", text: "We specialize in building digital solutions tailored specifically for businesses across a wide range of industries, including equipment, IT, real estate, and fashion rentals." },
    { icon: codecircle, title: "Full-Service Development Solutions", text: "From mobile and web application development to website creation and resource outsourcing, we offer end-to-end services to cover all your digital needs." },
    { icon: profile, title: "Seamless User Experience", text: "We believe in long-term partnerships. Our 24/7 customer support team ensures your platform runs smoothly, resolving technical issues, and addressing user inquiries promptly." },
    { icon: chart, title: "Future-Ready Technology", text: "Our clients have experienced tangible business improvements, from streamlined operations to better customer satisfaction and increased bookings." },
    { icon: messages, title: "Dedicated Customer Support", text: "We stay ahead of industry trends, using the latest technologies and tools to ensure your platform is future-ready." },
    { icon: chart, title: "Proven Track Record", text: "User experience is at the heart of what we do. We design intuitive, responsive interfaces that drive user engagement and satisfaction." },
];

function BestChoice() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        const appearOptions = {
            threshold: 0.5,
        };

        const faders = document.querySelectorAll('.fade-in');
        const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    return;
                } else {
                    entry.target.classList.add('appear');
                    appearOnScroll.unobserve(entry.target);
                }
            });
        }, appearOptions);

        faders.forEach(fader => {
            appearOnScroll.observe(fader);
        });

        return () => {
            faders.forEach(fader => {
                appearOnScroll.unobserve(fader);
            });
        };
    }, []);

    return (
        <div className='bg-light'>
            <Container>
                <p
                    className=" best_title text-start mb-0 ms-4 pt-5 fade-in"
                    style={{
                        color: Styles.colorBlack,
                        fontWeight: Styles.FontSemibold,
                        fontFamily: Styles.fontFamilyMontserrat,

                    }}>
                    Why We're Your Best Choice<span style={{ color: Styles.colorBlue }}>.</span>
                </p>



                <Row className="row-cols-lg-3 row-cols-md-2 row-cols-1">
                    {cardData.map((card, index) => (
                        <Col key={index}>
                            <Card className="h-100 text-start p-3 border-0 bg-transparent fade-in">
                                <Card.Body>
                                    <div className="d-flex align-items-center justify-content-center ms-0 mb-4"
                                        style={{
                                            backgroundColor: Styles.backgroundColorWhite,
                                            boxShadow: Styles.boxShadow,
                                            width: 45,
                                            height: 45,
                                            borderRadius: 30
                                        }}>
                                        <img src={card.icon} alt={card.title} className="icon" />
                                    </div>
                                    <Card.Title className='mt-3 mb-4' style={{ fontWeight: Styles.FontSemibold, fontSize: Styles.Font20, fontFamily: Styles.fontFamilyMontserrat }}>
                                        {card.title}
                                    </Card.Title>
                                    <Card.Text style={{ fontWeight: Styles.FontMedium, fontSize: Styles.Font14, fontFamily: Styles.fontFamilyRoboto }}>
                                        {card.text}
                                    </Card.Text>
                                </Card.Body>
                                <hr className="card-hr" />
                            </Card>
                        </Col>
                    ))}
                </Row>


            </Container>
        </div>
    );
}

export default BestChoice;

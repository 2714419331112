import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import QbatzNavbar from './Components/NavHeader';
import Home from './Components/Home';
import Innovation from './Components/Innovation';
import Services from './Components/Services';
import ContactUs from './Components/ContactUs';
import PeopleSays from './Components/PeopleSays';

const App = () => {
  return (
    <Router>
      <QbatzNavbar />
      <Routes>

        <Route path='/' element={<Home />} />
        <Route path="/products" element={<Innovation />} />
        <Route path="/services" element={<Services />} />
        <Route path="/testimonials" element={<PeopleSays />} />
        <Route path="/contact" element={<ContactUs/>} />

      </Routes>
    </Router>
  );
};

export default App;